import React, { useState } from "react";
import mainImg from "../images/official-img.png";
import PlusElement from "./PlusElement";

const Navbar = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="max-w-screen flex justify-between justify-items-center md:px-20 bg-[#404040] px-10">
      <div>
        <img src={mainImg} className="py-4" alt="Logo" />
      </div>
      <div>
        <PlusElement onClick={handleOpenPopup} />
      </div>
      {showPopup && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleClosePopup}
        >
          <div
            className="bg-[#282828] p-6 rounded-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-lg mb-4 text-white">
              You're currently unable to initiate a payout. <br />
              Please talk with your Agent to resolve the issue.
            </h2>
            <div className="grid ">
              {/* Add your popup content here */}
              <button
                className="bg-[#D6AF5A] hover:bg-[#D6AF5A] text-white px-4 py-2 rounded"
                onClick={handleClosePopup}
              >
                Close
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "https://t.me/alyssa_kuz";
                }}
                className="bg-[white] hover:text-white hover:bg-black animate duration-300 text-black float-right  px-4 py-2 rounded mt-4"
              >
                I want to cash out
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
