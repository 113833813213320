import React from "react";
import plus from "../images/Plusot.png";

const PlusElement = ({ onClick }) => {
  return (
    <div
      className="flex self-center items-center h-full cursor-pointer"
      onClick={onClick}
    >
      <img src={plus} className="py-4" />
      <span className="text-white">&nbsp;create payment</span>
    </div>
  );
};

export default PlusElement;
