import React, { useState } from "react";
import Balance from "./Balance";
import Transactions from "./Transactions";
import Summary from "./Summary";

const MobileTable = ({ mills, mm, dd }) => {
  const [activeTab, setActiveTab] = useState("balance");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case "balance":
        return <Balance mills={mills} />;
      case "transactions":
        return (
          <Transactions
            mills={mills}
            mm={mm}
            dd={dd}
          />
        );
      case "summary":
        return <Summary />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="grid grid-cols-3 pt-4">
        <div
          className={`cursor-pointer border-2 border-[#818181] text-center py-4 ${
            activeTab === "balance" ? "bg-[#D6AF5A] text-[#343434]" : ""
          }`}
          onClick={() => handleTabClick("balance")}
        >
          Balance
        </div>
        <div
          className={`cursor-pointer border-2 border-[#818181] text-center py-4 ${
            activeTab === "transactions" ? "bg-[#D6AF5A] text-[#343434]" : ""
          }`}
          onClick={() => handleTabClick("transactions")}
        >
          Transactions
        </div>
        <div
          className={`cursor-pointer border-2 border-[#818181] text-center py-4 ${
            activeTab === "summary" ? "bg-[#D6AF5A] text-[#343434]" : ""
          }`}
          onClick={() => handleTabClick("summary")}
        >
          Summary
        </div>
      </div>
      {renderActiveTab()}
    </div>
  );
};

export default MobileTable;
