import React from "react";

const Footer = () => {
  return (
    <div className="md:px-20 px-[5%] text-[#FFFFFF] pt-6 mb-10">
      <div className="text-2xl">Please note:</div>
      <div className="font-extralight">
        For further information regarding cash outs, please contact us directly:{" "}
        <br />
        <a
          className="underline"
          target="_blank"
          href="https://t.me/alyssa_kuz"
        >
          TRB Supervision Center
        </a>
      </div>
    </div>
  );
};

export default Footer;
