import React from "react";
import Transaction from "./Transaction";

const Transactions = ({ mills, sticks1, sticks2, sticks3, mm, dd }) => {
  const millions = parseInt(mills);
  const k = millions / 10;
  const tr1 = k.toLocaleString();
  const tr2 = (k * 3).toLocaleString();
  const tr3 = (k * 6).toLocaleString();

  // Populate the transactions array with the transactionValues
  return (
    <div className="">
      <div className="border-2 border-t-0 border-[#818181] ">
        <div>
          <div className="text-3xl py-2 border-b-2 border-[#818181] font-semibold px-10">
            Transactions
          </div>
          <div>
            <Transaction amount={tr1} mm={mm} dd={dd} sticks={sticks1} />
            <Transaction amount={tr2} mm={mm} dd={dd} sticks={sticks2} />
            <Transaction amount={tr3} mm={mm} dd={dd} sticks={sticks3} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
