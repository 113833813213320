import React from "react";

const Summary = () => {
  return (
    <di>
      <div className="border-2 border-t-0 border-[#818181] ">
        <div className="text-3xl border-b-2 border-[#818181] py-2 font-semibold px-10">
          Account Summary
        </div>
        <div className="grid grid-cols-2 justify-items-center  border-[#818181] ">
          <div className="w-full text-center py-2 px-4 border-r-2 border-[#818181]">
            Account status
          </div>
          <div className="w-full py-2 px-4 text-center text-[#7DD846]">
            Validated
          </div>
        </div>
        <div className="grid grid-cols-2 border-t-2 justify-items-center  border-[#818181] ">
          <div className="w-full py-2 px-4 text-center border-r-2 border-[#818181]">
            Requires documents
          </div>
          <div className="w-full flex justify-center align-items-middle text-center px-4 text-[#7DD846]">
            <span className="self-center">No</span>
          </div>
        </div>
        <div className="grid grid-cols-2 border-t-2 justify-items-center  border-[#818181] ">
          <div className="w-full py-2 px-4 text-center border-r-2 border-[#818181]">
            Scheduled cashouts
          </div>
          <div className="w-full flex justify-center align-items-middle text-center px-4 text-[#7DD846]">
            <span className="self-center">Yes</span>
          </div>
        </div>
        <div className="grid grid-cols-2 border-t-2 justify-items-center  border-[#818181] ">
          <div className="w-full py-2 px-4 text-center border-r-2 border-[#818181]">
            Works with an agent
          </div>
          <div className="w-full flex justify-center align-items-middle text-center px-4 text-[#7DD846]">
            <span className="self-center">Yes</span>
          </div>
        </div>
      </div>
    </di>
  );
};

export default Summary;
