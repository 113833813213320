import React from "react";
import wire from "../images/incoming-wire.svg";

const Transaction = ({ amount, sticks, mm, dd }) => {
  var tr = amount * 1000000;
  tr = tr.toLocaleString();
  return (
    <div
      className="px-10 py-2 grid grid-cols-2 items-center
    border-b-2 border-[#818181]
    "
    >
      <div className="grid grid-cols-3 items-center w-fit">
        <div className="justify-self-center">
          <img src={wire} alt="" />
        </div>
        <div className="col-span-2">
          <div className="grid grid-rows-2 ">
            <div>COD Transfer</div>
            <div className="text-[#C0C0C0] text-sm">
              <u>Incoming</u>
              <br />
              {mm}-{dd}-2024
            </div>
          </div>
        </div>
      </div>
      <div className="justify-self-end text-right text-lg text-[#90FF4D]">
        <span>
          ${tr}
          <br />
        </span>
        <span className="text-sm text-[#C0C0C0]">
          Required:
          <br /> <u className="text-[#90FF4D] text-base">Cash on Delivery Transfer</u>
        </span>
      </div>
    </div>
  );
};

export default Transaction;
