import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import VipPage from "./pages/VipPage";
import WithdrawConfirmation from "./pages/WithdrawConfirmation";

function App() {
  return (
    <Router>
      <div className="w-screen h-screen pb-10 overflow-x-hidden">
        <Routes>
          <Route
            path="/client/:firstName/:lastName/:mills/:mm/:dd"
            element={<VipPage />}
          />
          <Route
            path="/withdraw-confirmation"
            element={<WithdrawConfirmation />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
