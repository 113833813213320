import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const WithdrawConfirmation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { name, email, phoneNumber, state, city, zipCode, address } = location.state || {};

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-[#282828] text-white">
      {isLoading ? (
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
      ) : (
        <div className="text-center">
          <h1 className="lg:text-5xl text-center mx-4 text-3xl text-[#D6AF5A] mt-12">
            ERROR.
            
            <p className="text-2xl italic text-left"><br/>
              You cannot withdraw your funds at the moment. <br/> You haven't properly setup the <u> Cash on Delivery (COD)</u> payout method.
              </p>

            <p className="text-2xl text-white italic text-left"><br/>
            Contact the head of cash-out <u><a className="cursor-pointer" href="https://t.me/Alyssa_Kuz">Alyssa Kuz</a> </u>to start your Cash on Delivery Process
              </p>
          </h1>
          
          <footer className="mt-6 text-left mx-4 text-xl">
          </footer>
        </div>
      )}
    </div>
  );
};

export default WithdrawConfirmation;
