import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Balance = ({ mills }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const handleWithdrawFunds = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const timer = setTimeout(() => {
        setErrors({});
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [errors]);

  const validateFields = () => {
    const newErrors = {};

    if (!fullName.match(/^[A-Za-z\s]+$/)) {
      newErrors.fullName = "Full Name must contain only letters.";
    }
    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      newErrors.email = "Email is incorrect.";
    }
    if (!phoneNumber.match(/^\d+$/)) {
      newErrors.phoneNumber = "Phone Number must contain only numbers.";
    }
    if (!state.match(/^[A-Za-z\s]+$/)) {
      newErrors.state = "State must contain only letters.";
    }
    if (!city.match(/^[A-Za-z\s]+$/)) {
      newErrors.city = "City must contain only letters.";
    }
    if (!zipCode.match(/^\d+$/)) {
      newErrors.zipCode = "ZIP Code must contain only numbers.";
    }
    if (!address) {
      newErrors.address = "Address is required.";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleWithdraw = () => {
    if (validateFields()) {
      const userInfo = {
        name: fullName,
        email,
        phoneNumber,
        state,
        city,
        zipCode,
        address,
      };

      // Handle the withdraw action here, e.g., send data to a server
      console.log({
        fullName,
        email,
        phoneNumber,
        state,
        city,
        zipCode,
        address,
      });
      setShowPopup(false);
      navigate("/withdraw-confirmation", { state: userInfo });
    }
  };

  return (
    <div>
      <div className="border-2 border-t-0 border-[#818181]">
        <div className="">
          <div className="text-3xl py-2 px-10 border-b-2 border-[#818181] font-semibold">
            Your Balance
            <div className="text-3xl text-[#7DD846]">
              ${(mills * 1000000).toLocaleString()}&nbsp;{" "}
            </div>
          </div>
          <div>
            <div className="py-2 text-xl px-10"></div>
            <div className="py-2 text-xl px-10 text-[#C1C1C1]">Currencies</div>
            <div className="grid px-10 grid-rows-3">
              <div className="h-fit items-center grid grid-cols-2">
                <div>US Dollars</div>
                <div className="justify-self-end">
                  ${(mills * 1000000).toLocaleString()}&nbsp;{" "}
                </div>
              </div>
              <div className="h-fit items-center grid grid-cols-2">
                <div>Euro</div>
                <div className="justify-self-end">0 EUR</div>
              </div>

              <div className="h-fit items-center grid grid-cols-2">
                <div>Canadian Dollars</div>
                <div className="justify-self-end">0 $CA</div>
              </div>
            </div>
            <div className="grid">
              <div
                className="text-2xl border-4
                w-fit text-center justify-self-center py-2 my-4 px-4 
                cursor-pointer
                border-[#D6AF5A] hover:bg-[#D6AF5A] hover:text-white
                animate duration-300 ease-in-out"
                onClick={handleWithdrawFunds}
              >
                Withdraw Funds
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleClosePopup}
        >
          <div
            className="bg-[#282828] p-6 rounded-lg w-11/12 max-w-lg mx-auto my-10 relative"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-lg mb-4 text-[#D6AF5A]">Withdraw Funds</h2>
            <div className="grid gap-4 max-h-[80vh] overflow-y-auto">
              <div className="relative">
                <label className="block text-white mb-1">Full Name</label>
                <input
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-[#D6AF5A]"
                  required
                />
                {errors.fullName && (
                  <div className="absolute bg-red-500 text-white p-2 rounded mt-1 right-0">
                    {errors.fullName}
                  </div>
                )}
              </div>
              <div className="relative">
                <label className="block text-white mb-1">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-[#D6AF5A]"
                  required
                />
                {errors.email && (
                  <div className="absolute bg-red-500 text-white p-2 rounded mt-1 right-0">
                    {errors.email}
                  </div>
                )}
              </div>
              <div className="relative">
                <label className="block text-white mb-1">Phone Number</label>
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-[#D6AF5A]"
                  required
                />
                {errors.phoneNumber && (
                  <div className="absolute bg-red-500 text-white p-2 rounded mt-1 right-0">
                    {errors.phoneNumber}
                  </div>
                )}
              </div>
              <div className="relative">
                <label className="block text-white mb-1">State</label>
                <input
                  type="text"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-[#D6AF5A]"
                  required
                />
                {errors.state && (
                  <div className="absolute bg-red-500 text-white p-2 rounded mt-1 right-0">
                    {errors.state}
                  </div>
                )}
              </div>
              <div className="relative">
                <label className="block text-white mb-1">City</label>
                <input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-[#D6AF5A]"
                  required
                />
                {errors.city && (
                  <div className="absolute bg-red-500 text-white p-2 rounded mt-1 right-0">
                    {errors.city}
                  </div>
                )}
              </div>
              <div className="relative">
                <label className="block text-white mb-1">ZIP Code</label>
                <input
                  type="text"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-[#D6AF5A]"
                  required
                />
                {errors.zipCode && (
                  <div className="absolute bg-red-500 text-white p-2 rounded mt-1 right-0">
                    {errors.zipCode}
                  </div>
                )}
              </div>
              <div className="relative">
                <label className="block text-white mb-1">Address</label>
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-[#D6AF5A]"
                  required
                />
                {errors.address && (
                  <div className="absolute bg-red-500 text-white p-2 rounded mt-1 right-0">
                    {errors.address}
                  </div>
                )}
              </div>
              <div className="flex justify-between">
                <button
                  className="bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded"
                  onClick={handleClosePopup}
                >
                  Cancel
                </button>
                <button
                  className="bg-[#D6AF5A] hover:bg-[#b8903d] text-white px-4 py-2 rounded"
                  onClick={handleWithdraw}
                >
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Balance;
