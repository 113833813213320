import React, { useState, useEffect } from "react";
import MobileTable from "./MobileTable";

const HeroSection = ({
  firstName,
  lastName,
  mills,
  trans,
  mm,
  dd,
}) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  const fname = firstName[0].toUpperCase() + firstName.slice(1).toLowerCase();
  const lname = lastName[0].toUpperCase() + lastName.slice(1).toLowerCase();

  return (
    <div className="md:mx-20 mx-[5%] pt-5 text-white">
      <div className="md:text-4xl text-2xl md:pt-10">
        Welcome,&nbsp;
        <span className="font-bold">
          {fname}&nbsp;{lname}
        </span>
      </div>
      <div>Current system time:</div><div>{time.toLocaleString()}</div>
      <br/><div className="md:text-xl text-lg">Dear <b>{firstName} {lastName}</b>, your current outstanding balance is <b><u>${mills} million</u></b>. <br/>To receive it, initiate the Cash on Delivery process.</div><br/>
      <div>
        <MobileTable
          mills={mills}
          trans={trans}
          mm={mm}
          dd={dd}
        />
      </div>
    </div>
  );
};

export default HeroSection;
