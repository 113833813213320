import { useParams } from "react-router-dom";
import "./styles.css";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import Footer from "../components/Footer";

const VipPage = () => {
  const {
    firstName,
    lastName,
    mills,
    trans,
    mm,
    dd,
  } = useParams();

  console.log(firstName);

  return (
    <div className="h-fit ">
      <Navbar />
      <HeroSection
        firstName={firstName}
        lastName={lastName}
        mills={mills}
        mm={mm}
        dd={dd}
        trans={trans}
      />
      <div className="float-right">{/* <Bubble /> */}</div>
      <Footer />
    </div>
  );
};

export default VipPage;
